import lunches from "./lunch";
import JSZip from 'jszip'
import { saveAs } from 'file-saver'
// @ts-ignore
const userbox: HTMLInputElement = document.getElementById("username");
// @ts-ignore
const passbox: HTMLInputElement = document.getElementById("password");
// @ts-ignore
const submitbutton: HTMLInputElement = document.getElementById("submit");

type SuccessResp = {
    code: "SUCCESS"
    content: any
}
type FailResp = {
    code: "ERROR",
    content: {
        code: string
        error: string
    }
}
type Resp = FailResp | SuccessResp
async function fetchFromAPI(path: string, username: string, password: string): Promise<Resp> {
    path = "https://studentvue.wackery.com/"+path
    const resp = await fetch(path, {
        body: JSON.stringify({ username: username, password: password }),
        method: "POST"
    })
    return await resp.json()

}

function setBox(t:string) {
    document.getElementById("status").innerText = t
}

type Lunches = Record<string,number>

//const lunchData: Lunches = (window as any).lunches;

const lunchURL = "https://schedule-personalizer.github.io/schedule.js"

async function fixSchedule(t: SuccessResp) {
    const s = t.content;
    //const g = eval(await (await fetch(lunchURL)).text())
    s.DEBUG = {
        //lunches: g.lunches
        lunchDebug: {
            data: lunches,
            teacherID: s.ClassLists.ClassListing.find(element => element.Period == "3").TeacherStaffGU,
            detected: lunches[s.ClassLists.ClassListing.find(element => element.Period == "3").TeacherStaffGU]
        }
    }
    return s;
}

(window as any).sub = async (e: SubmitEvent) => {
    // submit logic
    e.preventDefault();
    userbox.disabled = true;
    passbox.disabled = true;
    submitbutton.disabled = true;
    const username = userbox.value;
    const password = passbox.value;
    setBox("Fetching info...")
    const _schedule = await fetchFromAPI("get_schedule", username, password)
    if (_schedule.code == "ERROR") {
        alert(_schedule.content.error);
        userbox.disabled=false;passbox.disabled=false;submitbutton.disabled=false;
        setBox("Waiting for input...")
        return
    }
    const schedule = await fixSchedule(_schedule);
    
    /*public set value(v : string) {
        this. = v;
    }*/
    setBox("Generating package")
    const z = JSZip()
    z.file("schedule.json",JSON.stringify(schedule,null,4))
    const zipf = await z.generateAsync({type:"blob", compression: "DEFLATE"})
    //setBox(btoa(JSON.stringify(schedule,null,4)))?
    setBox("Downloading...")
    saveAs(zipf, username+".zip")
    
    userbox.disabled = false;
    passbox.disabled = false;
    submitbutton.disabled = false;
    setBox("Waiting for input...")
    //alert("cringe");
}